<script>
	import { fade } from 'svelte/transition';

	export let degrees = {};

	const degreeLevels = [
		{ text: "Bachelor's & Associate's", data: degrees.undergraduate.programs },
		{ text: "Master's", data: degrees.graduate.programs },
		{ text: 'Doctoral', data: degrees.doctoral.programs },
		{ text: 'Certificate', data: degrees["post-masters-certificates"].programs },
	];
	let activeDegreeLevel;

	let selectElementPlaceholder = 'Please Select a Degree Level First';
	let selectedProgramId = -1;
	let activeProgram;

	let showCta = false;

	// console.log(degrees);
	let activePrograms = [];

	function selectChangeHandler(id) {
		let activePrograms = degreeLevels[activeDegreeLevel].data;
		activeProgram = activePrograms[id];
		// console.log(activeProgram);
		showCta = true;
	}

	function buttonPushHandler(level) {
		showCta = false;
		activePrograms = level.data;
		activeDegreeLevel = degreeLevels.indexOf(level);
		selectElementPlaceholder = 'Select a ' + level.text + ' Program to Explore';
		if (level.text == "Bachelor's & Associate's") {
			selectElementPlaceholder = selectElementPlaceholder.replace('&', 'or');
		}
		selectedProgramId = -1;
	}
</script>

<div class="program-filter-v2">
	<div class="degree-level-container">
		{#each degreeLevels as level, i}
			<button
				class={i == activeDegreeLevel
					? 'degree-level-btn active'
					: 'degree-level-btn'}
				on:click={() => buttonPushHandler(level)}>{level.text}</button
			>
		{/each}
	</div>
	<div class="program-select-container">
		<div class="program-select-wrapper">
			<label for="programSelect" class="program-select-label"
				>Explore Our Programs by Degree Level</label
			>
			<label for="programSelect" class="online-key">*Online Programs</label>
			<select
				id="programSelect"
				class="program-select"
				bind:value={selectedProgramId}
				on:change={() => selectChangeHandler(selectedProgramId)}
			>
				<option value={-1} disabled selected>{selectElementPlaceholder}</option>
				{#if activePrograms.length > 0}
					{#each activePrograms as program, id}
						<option value={id}>
							{program.title}{program.is_online === true
								? '* ' + program.specializations
								: ''}
						</option>
					{/each}
				{/if}
			</select>
			<div class="explore-program-container">
				{#if showCta}
					<button
						class="explore-program-cta button orange-fill"
						on:click={() => (location.href = activeProgram.url)}
						transition:fade={{ duration: 250 }}>Explore</button
					>
				{/if}
			</div>
		</div>
	</div>
</div>
